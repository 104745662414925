import { Listbox, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SearchingCountry = ({
	title,
	placeholder,
	onChange,
	isOpen = false,
	changeCountry = '',
	dataCountriesList,
	error,
	disabled
}) => {
	const {countries} = useSelector((state) => state.other);
	const [inputValue, setInputValue] = useState('');
	const [filteredCountries, setFilteredCountries] = useState([]);
	
	useEffect(() => {
		if (isOpen && typeof (changeCountry) !== 'object') setInputValue(changeCountry);
		if (inputValue !== '' && changeCountry === '') setInputValue('')
	}, [isOpen, changeCountry]);
	
	const filteredDataCountriesList = dataCountriesList?.filter((item) =>
		item.key !== undefined || (item.label !== undefined && item.value !== undefined)
	)
	
	const handleSearchChange = (newValue) => {
		setInputValue(newValue);
		
		if (!newValue) {
			setFilteredCountries([]);
			onChange('');
			return;
		}
		
		const filtered = (filteredDataCountriesList?.length > 0 ? filteredDataCountriesList : countries).filter((country) =>
			(filteredDataCountriesList?.length > 0 ? country.label : country.name).toLowerCase().startsWith(newValue.toLowerCase())
		);
		
		setFilteredCountries(filtered);
	};
	
	const handleCountrySelect = (countryCode) => {
		const selectedCountry = (filteredDataCountriesList?.length > 0 ? filteredDataCountriesList : countries).find(
			(country) => (filteredDataCountriesList?.length > 0 ? country.value : country.code) === countryCode
		);
		
		if (selectedCountry) {
			const countryName = filteredDataCountriesList?.length > 0 ? selectedCountry.label : selectedCountry.name;
			setInputValue(countryName);
			
			onChange(filteredDataCountriesList?.length > 0 ? selectedCountry.value : {
				id: 'country_id',
				newValue: selectedCountry.value || selectedCountry.code
			});
			setFilteredCountries([]);
		}
	};
	
	return (
		<div >
      <TextField
	      id='country_id'
	      type='search'
	      inputMode='text'
	      placeholder={placeholder}
	      label={title}
	      value={inputValue}
	      autoComplete='off'
	      onChange={handleSearchChange}
	      error={error}
	      disabled={disabled}
      />
			{filteredCountries.length > 0 && (
				<div className='custom-listbox'>
          <Listbox onSelect={handleCountrySelect}>
            {filteredCountries.map((country) => (
	            <Listbox.Option
		            key={filteredDataCountriesList?.length > 0 ? country.value : country.code}
		            value={filteredDataCountriesList?.length > 0 ? country.value : country.code}
	            >
                {filteredDataCountriesList?.length > 0 ? country.label : country.name}
              </Listbox.Option >
            ))}
          </Listbox >
        </div >
			)}
    </div >
	);
};

export default SearchingCountry;
