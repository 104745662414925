import {
  AlphaStack,
  Button,
  ButtonGroup,
  ChoiceList,
  Spinner,
  Text,
} from "@shopify/polaris";
import Modal from "components/Modal";
import useActionDialogs from "hooks/useActionDIalogs";
import useAppDispatch from "hooks/useAppDispatch";
import { t } from "i18next";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { deleteCurrentPlan } from "redux/features/subscription/subscriptionSlice";

import "./index.scss";

const dialogNames = ["cancelSubscription", "cancelSubscriptionComplete"];

const CancelSubscription = () => {
  const dispatch = useAppDispatch();
  const { actionDialogs, handleActionDialogsOpen, handleActionDialogsClose } =
    useActionDialogs(dialogNames);
  const { currentPlan, deletingPlanError } = useSelector(
    (state) => state.subscription
  );
  const { fetching } = useSelector((state) => state.user);
  const [subsForDel, setSubsForDel] = useState([]);

  const doDeleteCurrentPlan = () => {
    const data = {
      subs_types: subsForDel,
    };

    dispatch(deleteCurrentPlan(data)).finally(() => {
      setSubsForDel([]);
      handleActionDialogsClose("cancelSubscription");
      handleActionDialogsOpen("cancelSubscriptionComplete");
    });
  };

  function renderCancelSubscription() {
    const subsList = [];

    if (currentPlan.subscriptions) {
      subsList.push({
        label: `Tariff plan vat: ${currentPlan.subscriptions.tariff_plan.name}`,
        value: "vat_sbs",
      });
    }
    if (currentPlan.subscriptions_sales_tax) {
      subsList.push({
        label: `Tariff plan sales tax: ${currentPlan.subscriptions_sales_tax.tariff_plan.name}`,
        value: "sales_tax_sbs",
      });
    }
    if (currentPlan.subscriptions_epr) {
      subsList.push({
        label: `Tariff plan epr: ${currentPlan.subscriptions_epr.name}`,
        value: "epr_sbs",
      });
    }
    if (currentPlan.subscriptions_omp) {
      subsList.push({
        label: `Tariff plan omp: ${currentPlan.subscriptions_omp.tariff_plan.name}`,
        value: "omp_sbs",
      });
    }

    const onClose = () => {
      handleActionDialogsClose("cancelSubscription");
      setSubsForDel([]);
    };

    return (
      <Modal
        title={`${t("profileMemb.cancelSub")}?`}
        visible={actionDialogs.cancelSubscription.open}
        description={
          <div className='cancelSub-description'>
            <p>{t("profileMemb.weAreSorry")}</p>
            <p>{t("profileMemb.confirmTheCanc")}</p>
          </div>
        }
        onClose={onClose}
        iconType='danger'
        content={
          <ChoiceList
            allowMultiple
            choices={subsList}
            selected={subsForDel}
            onChange={(subsForDel) => setSubsForDel(subsForDel)}
          />
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large' fullWidth>
              {t("profileMemb.goBack")}
            </Button>
            <Button
              destructive
              disabled={_.isEmpty(subsForDel)}
              onClick={doDeleteCurrentPlan}
              size='large'
              fullWidth
            >
              {t("profileMemb.confirm")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  function renderCancelSubscriptionComplete() {
    return (
      <Modal
        title={deletingPlanError ?? "Subscription was cancelled successfully!"}
        visible={actionDialogs.cancelSubscriptionComplete.open}
        description={
          deletingPlanError
            ? "Something went wrong. Please try again later."
            : null
        }
        iconType={deletingPlanError ? "danger" : "success"}
        onClose={() => {
          handleActionDialogsClose("cancelSubscriptionComplete");
        }}
      />
    );
  }

  return (
    <>
      {fetching ? (
        <Spinner />
      ) : (
        <>
          <AlphaStack spacing='2'>
            <Text variant='headingMd'>
              Want to{" "}
              <Button
                destructive
                plain
                onClick={() => handleActionDialogsOpen("cancelSubscription")}
              >
                cancel
              </Button>{" "}
              your subscription?
            </Text>
          </AlphaStack>
        </>
      )}
      {currentPlan && renderCancelSubscription()}
      {renderCancelSubscriptionComplete()}
    </>
  );
};

export default CancelSubscription;
